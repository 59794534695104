import { isVimOS, VimOsMigrationAPIProvider } from '@getvim-core-apps/vim-os-migration-api';
import { VimSDKProvider } from '@getvim/internal-vim-os-sdk/react';
import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import { AnalyticsHover as AnalyticsHoverProvider, ErrorBoundaryWrapper } from '@getvim/atomic-ui';
import { FeatureFlagProvider } from '@getvim/feature-flags-react';
import {
  BaseWidgetAnalyticsEventTypes,
  VimProvider as OldVimProvider,
} from '@getvim/react-app-infra';
import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { GraphqlClient } from './api/graphqlClient';
import App from './App';
import { widgetId } from './consts';
import { analyticsClient } from './utils/analytics';
import { featureFlagsConfig } from './utils/featureFlags';
import { eligibilityLogger } from './utils/logger';
import sdkOptions from './sdkOptions';
import { analyticsManager } from './utils/analyticManager';
import { useAnalytics } from './use-analytics';
import { AppEnv } from './utils/env';
import { TelemetryProvider } from '@getvim/opentelemetry-sdk/react';

const handleRenderError = ({ error }) => {
  eligibilityLogger.error('Client application crashed due to render error', {
    error,
  });
};

const handleHoverStart = () => {
  analyticsManager.track(BaseWidgetAnalyticsEventTypes.appHoverStart, {
    app_name: widgetId,
    is_scroll_available: document.body.scrollHeight > document.body.clientHeight,
  });
};

const handleHoverEnd = (time) => {
  analyticsManager.track(BaseWidgetAnalyticsEventTypes.appHoverEnd, {
    app_name: widgetId,
    app_hover_opened_for_time: time,
  });
};

const AnalyticsHoverProviderWrapper = ({ children }) => {
  return (
    <AnalyticsHoverProvider
      className="app-hover"
      onHoverAction={handleHoverStart}
      onLeaveAction={handleHoverEnd}
    >
      {children}
    </AnalyticsHoverProvider>
  );
};

const SdkWrapper = ({ children }) =>
  isVimOS() ? <VimSDKProvider {...sdkOptions}>{children}</VimSDKProvider> : children;

const LegacyRuntimeWrapper = ({ children }) =>
  isVimOS() ? (
    children
  ) : (
    <OldVimProvider applicationId={widgetId} onAccessTokenChanged={GraphqlClient.setAccessToken}>
      {children}
    </OldVimProvider>
  );

/* eslint-disable @typescript-eslint/no-explicit-any */
const appEnv: AppEnv = (window as any).$vim_environment?.APP_ENV;

const AnalyticsWrapper = ({ children }) => {
  const analyticsReady = useAnalytics();
  return analyticsReady ? children : null;
};

const Index = () => {
  return (
    <TelemetryProvider
      options={{
        serviceName: widgetId,
        env: appEnv,
        enableLogs: appEnv === AppEnv.DEV,
        enableMetrics: appEnv === AppEnv.DEV,
        enableTraces: appEnv === AppEnv.DEV,
      }}
    >
      <SdkWrapper>
        <React.StrictMode>
          <IntlProvider locale="en">
            <FeatureFlagProvider {...featureFlagsConfig}>
              <ApolloProvider
                client={
                  GraphqlClient.eligibilityBFFClient as unknown as ApolloClient<NormalizedCacheObject>
                }
              >
                <LegacyRuntimeWrapper>
                  <VimOsMigrationAPIProvider
                    widgetId={widgetId}
                    runtimeAnalyticsClient={analyticsClient}
                    updateNotificationCount={false}
                    setAccessToken={GraphqlClient.setAccessToken}
                  >
                    <ErrorBoundaryWrapper
                      onRenderError={handleRenderError}
                      applicationDisplayName={widgetId}
                    >
                      <AnalyticsWrapper>
                        <AnalyticsHoverProviderWrapper>
                          <App />
                        </AnalyticsHoverProviderWrapper>
                      </AnalyticsWrapper>
                    </ErrorBoundaryWrapper>
                  </VimOsMigrationAPIProvider>
                </LegacyRuntimeWrapper>
              </ApolloProvider>
            </FeatureFlagProvider>
          </IntlProvider>
        </React.StrictMode>
      </SdkWrapper>
    </TelemetryProvider>
  );
};

ReactDOM.render(<Index />, document.getElementById('root'));
