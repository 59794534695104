import { isVimOS } from '@getvim-core-apps/vim-os-migration-api';
import { AppAnalyticsBaseProperties, EligibilityAnalyticsEvents } from './analytics';
import { eligibilityLogger } from './logger';
import { TrackPayload } from '@getvim/internal-vim-os-sdk/types';

export interface EligibilityAnalyticsClient {
  trackAnalytics: (payload: TrackPayload) => void;
}

export class EligibilityAnalyticsManager {
  private baseProperties: AppAnalyticsBaseProperties = {
    vim_patient_id: undefined,
    ehr_insurance: undefined,
    vim_insurer: undefined,
  };

  private static analyticsClient: EligibilityAnalyticsClient;
  public static setAnalyticsClient(client: EligibilityAnalyticsClient) {
    EligibilityAnalyticsManager.analyticsClient = client;
  }

  public setPatientBaseProperties({
    vimPatientId,
    ehrInsurance,
    insurer,
  }: {
    vimPatientId: string;
    ehrInsurance: string;
    insurer: string;
  }) {
    eligibilityLogger.debug('[AnalyticsManager]: patient base properties update', {
      vimPatientId,
      ehrInsurance,
      insurer,
    });
    const properties = {
      base_properties: {
        vim_patient: {
          vim_patient_id: vimPatientId,
          ehr_insurance: ehrInsurance,
          vim_insurer: insurer,
        },
      },
    };
    this.baseProperties = { ...this.baseProperties, ...properties };
  }

  public track<T extends keyof EligibilityAnalyticsEvents>(
    event: T,
    eventProperties: EligibilityAnalyticsEvents[T],
  ) {
    EligibilityAnalyticsManager.analyticsClient.trackAnalytics({
      event,
      properties: {
        ...eventProperties,
        ...this.baseProperties,
        is_vim_os_app: isVimOS(),
      },
    });
  }
}

export const analyticsManager = new EligibilityAnalyticsManager();
