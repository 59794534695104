import { useApi } from '@getvim/internal-vim-os-sdk/react';
import { useEffect, useMemo, useState } from 'react';
import { eligibilityLogger } from '../utils/logger';
import { EligibilityAnalyticsManager } from '../utils/analyticManager';

export const useSdkAnalytics = () => {
  const { trackAnalytics } = useApi();
  const [analyticsReady, setAnalyticsReady] = useState<boolean>(false);

  const analyticsClient = useMemo(
    () => ({
      trackAnalytics,
    }),
    [trackAnalytics],
  );

  useEffect(() => {
    if (!analyticsClient?.trackAnalytics) {
      eligibilityLogger.warning('analyticsClient not initialized yet');
      return;
    }

    EligibilityAnalyticsManager.setAnalyticsClient(analyticsClient);

    setAnalyticsReady(true);
    eligibilityLogger.info('analyticsClient initialized successfully');
  }, [analyticsClient]);

  return analyticsReady;
};
