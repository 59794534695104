import { BaseWidgetAnalyticsEvents, BaseWidgetAnalyticsEventTypes } from '@getvim/react-app-infra';
import { AnalyticsClient } from '@getvim/utils-vim-connect-communication';
import { widgetId } from '../consts';
import { ExportAnalyticsEventNames, ExportAnalyticsEvents } from '../types/export-types';

export type AppAnalyticsBaseProperties = {
  vim_patient_id?: string | null;
  vim_insurer?: string | null;
  ehr_insurance?: string | null;
};

export enum ClickAction {
  Open = 'open',
  Close = 'close',
}

export enum Feature {
  Eligibility = 'ELIGIBILITY',
}

export enum DetailsCardType {
  MemberDetails = 'MEMBER_DETAILS',
  InNetworkDeductibles = 'IN_NETWORK_DEDUCTIBLES',
  OutOfNetworkDeductibles = 'OUT_OF_NETWORK_DEDUCTIBLES',
}

type EligibilityClickProperties = {
  feature: Feature.Eligibility;
  action: ClickAction;
  detailsCardType: DetailsCardType;
  app_source_displayed?: string;
};

export type EligibilityAnalyticsEvents = BaseWidgetAnalyticsEvents & {
  eligibility_app_enabled: {
    vim_patient_id?: string;
    content_supplier_patient_id?: string;
    app_source_enabled: string[];
    app_source_displayed: string[] | undefined;
    items_received: DetailsCardType[];
    execution_time?: number;
  };
  [BaseWidgetAnalyticsEventTypes.itemHover]: BaseWidgetAnalyticsEvents[BaseWidgetAnalyticsEventTypes.itemHover] & {
    card_type: DetailsCardType;
  };
  vim_chart_item_click: EligibilityClickProperties;
  tooltip_hovered: {
    app_name: string;
    tooltip_item_title: string;
  };
  [ExportAnalyticsEventNames.EXPORT_MENU_BUTTON_CLICKED]: ExportAnalyticsEvents[ExportAnalyticsEventNames.EXPORT_MENU_BUTTON_CLICKED];
  [ExportAnalyticsEventNames.EXPORT_MENU_INTERACTION]: ExportAnalyticsEvents[ExportAnalyticsEventNames.EXPORT_MENU_INTERACTION];
  [ExportAnalyticsEventNames.EXPORT_MENU_ACTION_COMPLETED]: ExportAnalyticsEvents[ExportAnalyticsEventNames.EXPORT_MENU_ACTION_COMPLETED];
};

export const analyticsClient = new AnalyticsClient<EligibilityAnalyticsEvents>(widgetId);
