import React, { FC, useCallback } from 'react';
import { DetailsCard } from '@getvim/atomic-ui';
import { every } from 'lodash-es';
import { Deductible, DeductibleExpenditures } from '../../../types';
import { DeductibleCardItem, shouldShowDeductibleSection } from '../deductible-card-item';
import { ClickAction, Feature, DetailsCardType } from '../../../utils/analytics';
import { analyticsManager } from '../../../utils/analyticManager';

export enum Network {
  InNetwork,
  OutOfNetwork,
}

export interface NetworkDetailsListProps {
  network: Network;
  deductibleDetails: Deductible;
  dataSource?: string;
}

const networkToTitle = {
  [Network.InNetwork]: 'In-network details',
  [Network.OutOfNetwork]: 'Out-of-network details',
};

export const shouldShowNetworkCard = (deductibleDetails?: Deductible) => {
  const { individualNetworkDeductibleDetails, familyNetworkDeductibleDetails } =
    deductibleDetails || {};

  const { deductible: individualDeductible, outOfPocket: individualOutOfPocket } =
    individualNetworkDeductibleDetails ?? {};
  const { deductible: familyDeductible, outOfPocket: familyOutOfPocket } =
    familyNetworkDeductibleDetails ?? {};

  return (
    deductibleDetails &&
    !every(
      [individualDeductible, individualOutOfPocket, familyDeductible, familyOutOfPocket],
      (section) => !shouldShowDeductibleSection(section),
    )
  );
};

export const NetworkDetailsList: FC<NetworkDetailsListProps> = ({
  network,
  deductibleDetails,
  dataSource,
}) => {
  const onToggle = useCallback((expanded: boolean, network: Network, dataSource?: string) => {
    analyticsManager.track('vim_chart_item_click', {
      feature: Feature.Eligibility,
      action: expanded ? ClickAction.Open : ClickAction.Close,
      app_source_displayed: dataSource,
      detailsCardType:
        network === Network.InNetwork
          ? DetailsCardType.InNetworkDeductibles
          : DetailsCardType.OutOfNetworkDeductibles,
    });
  }, []);

  const handleToggle = useCallback(
    (expanded: boolean) => {
      onToggle(expanded, network, dataSource);
    },
    [network, onToggle, dataSource],
  );

  if (!shouldShowNetworkCard(deductibleDetails)) {
    return <></>;
  }

  const { individualNetworkDeductibleDetails, familyNetworkDeductibleDetails } =
    deductibleDetails as Deductible;

  const { deductible: individualDeductibles, outOfPocket: individualOutOfPocket } =
    individualNetworkDeductibleDetails ?? {};

  const { deductible: familyDeductibles, outOfPocket: familyOutOfPocket } =
    familyNetworkDeductibleDetails ?? {};

  const deductibleCardItemDetails: {
    itemTitle: string;
    deductibleSpent?: DeductibleExpenditures;
    informationText: string;
  }[] = [
    {
      itemTitle: 'Individual deductible',
      deductibleSpent: individualDeductibles,
      informationText:
        'The amount the member owes for health care services that the health insurance or plan covers before the health insurance or plan begins to pay. The deductible may apply to all services.',
    },
    {
      itemTitle: 'Family deductible',
      deductibleSpent: familyDeductibles,
      informationText:
        'The amount the family owes for health care services that the health insurance or plan covers before the health insurance or plan begins to pay. The deductible may apply to all services.',
    },
    {
      itemTitle: 'Individual out-of-pocket limit',
      deductibleSpent: individualOutOfPocket,
      informationText:
        "The most that the member pays during a policy period (usually a year) before the health insurance or plan begins to pay 100% of the allowed amount. This limit never includes the premium, balance-billed charges or health care that the health insurance or plan doesn't cover. Some health insurance or plans don't count all of the patient's co-payments, deductibles, co-insurance payments, out-of-network payments or other expenses toward this limit.",
    },
    {
      itemTitle: 'Family out-of-pocket limit',
      deductibleSpent: familyOutOfPocket,
      informationText:
        "The most that the family pays during a policy period (usually a year) before the health insurance or plan begins to pay 100% of the allowed amount. This limit never includes the premium, balance-billed charges or health care that the health insurance or plan doesn't cover. Some health insurance or plans don't count all of the patient's co-payments, deductibles, co-insurance payments, out-of-network payments or other expenses toward this limit.",
    },
  ];

  return (
    <DetailsCard
      title={networkToTitle[network]}
      iconClass={network === Network.InNetwork ? 'icon-member-marked-shield' : 'icon-member-shield'}
      onToggle={handleToggle}
      smallContentPadding
    >
      {deductibleCardItemDetails.map(({ itemTitle, deductibleSpent, informationText }, index) => {
        const idx = `${network}__deductible-card-item__${index}`;
        return (
          <DeductibleCardItem
            key={idx}
            network={network}
            itemTitle={itemTitle}
            deductibleSpent={deductibleSpent}
            informationText={informationText}
          />
        );
      })}
    </DetailsCard>
  );
};
