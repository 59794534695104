import {
  ContentSupplierIdentifiersValues,
  PatientContentSupplierIdentifiers,
  PatientSourceVaultToken,
  ProductSource,
} from './types';

export const namespaceToInsurerMap: Record<string, ProductSource> = {
  uhc: ProductSource.UnitedHealthCare,
  florida: ProductSource.BlueCrossBlueShield,
  anthem: ProductSource.AnthemBlueCross,
  premera: ProductSource.PremeraBlueCross,
};

export const extractPatientSourceIdentifiers = (
  contentSupplierIdentifiers: PatientContentSupplierIdentifiers = {},
  namespace: string,
): ContentSupplierIdentifiersValues | undefined => {
  const source = namespaceToInsurerMap[namespace] ?? namespace;
  return contentSupplierIdentifiers[source];
};

export const extractPatientSourceVaultToken = (
  patientSourceVaultTokens: PatientSourceVaultToken[] = [],
  namespace: string,
): PatientSourceVaultToken | undefined => {
  const source = namespaceToInsurerMap[namespace] ?? namespace;
  return patientSourceVaultTokens.find((currentSource) => currentSource.source === source);
};
