import { isNumber } from 'lodash-es';
import { widgetId } from '../../../consts';
import { DeductibleExpenditures } from '../../../types';
import { analyticsManager } from '../../../utils/analyticManager';

const isPositiveNumber = (value?: number) => isNumber(value) && value > 0;

export const shouldShowDeductibleSection = (deductibleSpent?: DeductibleExpenditures): boolean => {
  const { currentSpent, maximumAllowed } = deductibleSpent ?? {};

  const validValues = isPositiveNumber(currentSpent) || isPositiveNumber(maximumAllowed);

  const invalidNumbers =
    isPositiveNumber(currentSpent) &&
    isPositiveNumber(maximumAllowed) &&
    currentSpent! > maximumAllowed!;

  return validValues && !invalidNumbers;
};

type ProgressBarDetails = {
  percentage: number;
  valueLabel: string;
  maxValueLabel: string;
  percentageLabel?: string;
};

export const calculateDeductibleDetails = (
  amountSpent?: number,
  maximumAllowed?: number,
): ProgressBarDetails | undefined => {
  if (!isPositiveNumber(amountSpent) && !isPositiveNumber(maximumAllowed)) {
    // If both undefined or both equal 0 -> do not present progress-bar
    return;
  }

  if (!isNumber(maximumAllowed) || maximumAllowed === 0) {
    return {
      percentage: 0,
      valueLabel: `$${amountSpent!.toLocaleString()}`,
      maxValueLabel: 'N/A',
    };
  }

  if (!isNumber(amountSpent)) {
    return {
      percentage: 0,
      valueLabel: 'N/A',
      maxValueLabel: `$${maximumAllowed.toLocaleString()}`,
    };
  }

  if (amountSpent > maximumAllowed) {
    return;
  }

  const percentage = (amountSpent / maximumAllowed) * 100;
  return {
    percentage,
    valueLabel: `$${amountSpent.toLocaleString()}`,
    maxValueLabel: `$${maximumAllowed.toLocaleString()}`,
    percentageLabel: `(${percentage.toFixed(0)}%)`,
  };
};

export const onHoverTooltip = (itemTitle) => () => {
  analyticsManager.track('tooltip_hovered', {
    app_name: widgetId,
    tooltip_item_title: itemTitle,
  });
};
