import { useEffect, useState } from 'react';
import { EligibilityAnalyticsEvents, analyticsClient } from '../utils/analytics';
import { EligibilityAnalyticsManager } from '../utils/analyticManager';

export const useRuntimeAnalytics = () => {
  const [analyticsReady, setAnalyticsReady] = useState<boolean>(false);

  useEffect(() => {
    if (!analyticsClient) {
      return;
    }

    EligibilityAnalyticsManager.setAnalyticsClient({
      trackAnalytics: ({ event, properties }) =>
        analyticsClient.track(event as keyof EligibilityAnalyticsEvents, properties),
    });

    setAnalyticsReady(true);
  }, []);

  return analyticsReady;
};
