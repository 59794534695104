import React, { FC, useEffect, useState } from 'react';
import { useVimOsMigrationAPI } from '@getvim-core-apps/vim-os-migration-api';
import { themes, ThemeVariablesWrapper } from '@getvim/components-hooks-use-theme';
import EligibilityContainer from './atomic/EligibilityContainer';
import { GlobalContext, GlobalContextProps } from './context';

import '@getvim/atomic-ui/assets/styles/main.less';
import './App.less';
import { useFeatureFlag } from '@getvim/feature-flags-react';
import { usePatientLookupSharedService } from './hooks/usePatientLookupSharedService';
import { AppPatient } from './types';

const App: FC = () => {
  const {
    config,
    ehrState: { patient: ehrPatient },
    platformActions: { modifyWidget },
  } = useVimOsMigrationAPI();

  const [patient, setPatient] = useState<AppPatient>();

  const [shouldUseSourceVaultTokens, shouldUseSourceVaultTokensLoading] = useFeatureFlag({
    flagName: 'shouldUseSourceVaultTokens',
    defaultValue: false,
    flagContext: {
      organizationId_string: config.organization.id?.toString(),
      organizationName: config.organization.name,
    },
  });

  usePatientLookupSharedService(
    ehrPatient,
    setPatient,
    shouldUseSourceVaultTokens,
    shouldUseSourceVaultTokensLoading,
  );

  const [globalContext, setGlobalContext] = useState<GlobalContextProps>({
    featureFlags: { shouldUseSourceVaultTokens, shouldUseSourceVaultTokensLoading },
  });

  useEffect(() => {
    setGlobalContext((context) => ({
      ...context,
      featureFlags: {
        shouldUseSourceVaultTokens,
        shouldUseSourceVaultTokensLoading,
      },
    }));
  }, [shouldUseSourceVaultTokens, shouldUseSourceVaultTokensLoading]);

  useEffect(() => {
    if (!ehrPatient) {
      modifyWidget({
        notifications: 0,
        disabled: true,
        loading: false,
      });
    }
  }, [ehrPatient, modifyWidget]);

  return (
    <GlobalContext.Provider value={globalContext}>
      <ThemeVariablesWrapper theme={themes[config.organization.theme] || themes.vimConnect}>
        {!shouldUseSourceVaultTokensLoading && patient ? (
          <EligibilityContainer patient={patient} />
        ) : null}
      </ThemeVariablesWrapper>
    </GlobalContext.Provider>
  );
};

export default App;
