import {
  Button,
  CheckboxInput,
  ColorNameEnum,
  Popover,
  SizeEnum,
  Text,
  Tooltip,
  WeightEnum,
  usePopoverState,
} from '@getvim/atomic-ui';
import React, { FC, useState } from 'react';

import { EligibilitySections, GetMemberResult } from '../../../types';
import { shouldShowNetworkCard } from '..';

import { widgetId } from '../../../consts';
import { ExportAnalyticsEventNames, ExportMenuOption } from '../../../types/export-types';

import './index.less';
import { analyticsManager } from '../../../utils/analyticManager';

export interface ExportPdfMenuProps {
  onClick: (sectionsSelected: EligibilitySections) => void;
  eligibility?: Partial<GetMemberResult>;
}

const sendEligibilityExportMenuButtonClicked = (dataSource?: string) => {
  analyticsManager.track(ExportAnalyticsEventNames.EXPORT_MENU_BUTTON_CLICKED, {
    app_name: widgetId,
    interaction_type: 'opened',
    app_source_displayed: dataSource,
  });
};

export const ExportPdfMenu: FC<ExportPdfMenuProps> = ({ onClick, eligibility }) => {
  const { inNetwork, outOfNetwork } = eligibility ?? {};

  const [selectedSection, setSelectedSection] = useState<EligibilitySections>({
    memberAndEligibility: true,
    ...(shouldShowNetworkCard(inNetwork) ? { inNetworkDetails: true } : {}),
    ...(shouldShowNetworkCard(outOfNetwork) ? { outOfNetworkDetails: true } : {}),
  });

  const popover = usePopoverState({ placement: 'bottom-start' });

  const resetState = () => {
    setSelectedSection({
      memberAndEligibility: true,
      ...(shouldShowNetworkCard(inNetwork) ? { inNetworkDetails: true } : {}),
      ...(shouldShowNetworkCard(outOfNetwork) ? { outOfNetworkDetails: true } : {}),
    });
  };

  const onPopoverOpen = () => {
    popover.show();
  };

  const onPopoverClose = () => {
    popover.hide();
  };

  const onConfirm = () => {
    onPopoverClose();
    onClick(selectedSection);
    analyticsManager.track(ExportAnalyticsEventNames.EXPORT_MENU_INTERACTION, {
      app_name: widgetId,
      sections_available: Object.keys(selectedSection) as Array<keyof EligibilitySections>,
      section_selected: Object.keys(selectedSection).filter(
        (section) => selectedSection[section],
      ) as Array<keyof EligibilitySections>,
      action_type: 'confirm',
      export_option: ExportMenuOption.DOWNLOAD,
      app_source_displayed: eligibility?.dataSource,
    });
    resetState();
  };

  return (
    <Tooltip tooltipContent="Download PDF" bgColor="light">
      <div
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <button
          className="export-btn"
          type="button"
          onClick={() => {
            sendEligibilityExportMenuButtonClicked(eligibility?.dataSource);
            if (!shouldShowNetworkCard(inNetwork) && !shouldShowNetworkCard(outOfNetwork)) {
              onConfirm();
            } else {
              onPopoverOpen();
            }
          }}
        >
          <i className="icon-export-2" />
        </button>
        <Popover
          hideOnEsc={false}
          popover={popover}
          className="pdf-popover"
          aria-label="Choose Popover"
        >
          <div
            onClick={(event) => {
              event.stopPropagation();
            }}
            style={{ width: '100%' }}
          >
            <div className="pdf-sections-choose-popover-content">
              <div className="pdf-sections-choose-popover-title">
                <Text
                  text="Which sections to copy?"
                  size={SizeEnum['14px']}
                  weight={WeightEnum.black}
                  colorName={ColorNameEnum.ultraDark}
                  className="title-text"
                  align="left"
                />
                <Button
                  bgColor="none"
                  buttonType="tiny"
                  onClick={() => {
                    analyticsManager.track(ExportAnalyticsEventNames.EXPORT_MENU_INTERACTION, {
                      app_name: widgetId,
                      sections_available: Object.keys(selectedSection) as Array<
                        keyof EligibilitySections
                      >,
                      action_type: 'cancel',
                      export_option: ExportMenuOption.DOWNLOAD,
                      app_source_displayed: eligibility?.dataSource,
                    });
                    onPopoverClose();
                  }}
                >
                  <i className="icon-x" />
                </Button>
              </div>
              <div className="sections-checkboxes">
                <CheckboxInput
                  className="margin-left-20 margin-top-20"
                  id="memberAndEligibility"
                  checked={selectedSection.memberAndEligibility}
                  label="Member & eligibility details"
                  onChange={(value: any) =>
                    setSelectedSection({
                      ...selectedSection,
                      memberAndEligibility: value.currentTarget.checked,
                    })
                  }
                />
                {'inNetworkDetails' in selectedSection && (
                  <CheckboxInput
                    className="margin-left-20 margin-top-20"
                    id="inNetworkDetails"
                    checked={selectedSection.inNetworkDetails}
                    label="In-network details"
                    onChange={(value: any) =>
                      setSelectedSection({
                        ...selectedSection,
                        inNetworkDetails: value.currentTarget.checked,
                      })
                    }
                  />
                )}
                {'outOfNetworkDetails' in selectedSection && (
                  <CheckboxInput
                    className="margin-left-20 margin-top-20"
                    id="outOfNetworkDetails"
                    checked={selectedSection.outOfNetworkDetails}
                    label="Out-of-network details"
                    onChange={(value: any) =>
                      setSelectedSection({
                        ...selectedSection,
                        outOfNetworkDetails: value.currentTarget.checked,
                      })
                    }
                  />
                )}
              </div>
              <div className="section-action-buttons">
                <Button
                  buttonType="tiny"
                  bgColor="themedOutline"
                  onClick={onConfirm}
                  disabled={
                    !selectedSection.inNetworkDetails &&
                    !selectedSection.memberAndEligibility &&
                    !selectedSection.outOfNetworkDetails
                  }
                >
                  Confirm
                </Button>

                <Button
                  buttonType="tiny"
                  bgColor="themedOutline"
                  onClick={() => {
                    analyticsManager.track(ExportAnalyticsEventNames.EXPORT_MENU_INTERACTION, {
                      app_name: widgetId,
                      sections_available: Object.keys(selectedSection) as Array<
                        keyof EligibilitySections
                      >,
                      action_type: 'cancel',
                      export_option: ExportMenuOption.DOWNLOAD,
                      app_source_displayed: eligibility?.dataSource,
                    });
                    onPopoverClose();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </Popover>
      </div>
    </Tooltip>
  );
};
