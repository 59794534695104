import { useEffect, useState } from 'react';
import { useSharedServiceState } from '@getvim/internal-vim-os-sdk/react';
import { PatientSourceVaultToken } from '../types';
import { eligibilityLogger } from '../utils/logger';
import { getPatientInsurance } from '../utils/insurer.utils';
import { analyticsManager } from '../utils/analyticManager';

export function usePatientLookupSharedService(
  ehrPatient,
  setPatient,
  shouldUseSourceVaultTokens: boolean,
  shouldUseSourceVaultTokensLoading: boolean,
) {
  const serviceState = useSharedServiceState();

  const [patientLookupResponse, setPatientLookupResponse] = useState<{
    patientSourceVaultTokens: PatientSourceVaultToken[];
    patientToken: string;
  }>();

  useEffect(() => {
    const { 'patient-lookup-service': patientLookupService } = serviceState;
    if (patientLookupService) {
      const { patientLookupResponse: patientLookupServiceRespose } =
        patientLookupService.customMessage.payload;
      if (patientLookupServiceRespose.patientToken !== patientLookupResponse?.patientToken) {
        setPatientLookupResponse(patientLookupServiceRespose);
      } else {
        eligibilityLogger.info(
          'Received new patient-lookup-service event for same patientToken. skipping',
        );
      }
    }
  }, [serviceState]);

  useEffect(() => {
    if (!shouldUseSourceVaultTokensLoading && shouldUseSourceVaultTokens) {
      if (
        ehrPatient &&
        patientLookupResponse &&
        patientLookupResponse.patientToken === ehrPatient.token
      ) {
        eligibilityLogger.info('Initialize AppPatient with patientSourceVaultTokens', {
          ehrPatient,
          patientLookupResponse,
        });

        const patientInsurance = getPatientInsurance(
          ehrPatient.insurance,
          patientLookupResponse.patientSourceVaultTokens,
        );

        setPatient({
          ...ehrPatient,
          patientSourceVaultTokens: patientLookupResponse.patientSourceVaultTokens,
          insurance: patientInsurance,
        });

        analyticsManager.setPatientBaseProperties({
          vimPatientId: ehrPatient?.vimPatientId || null,
          ehrInsurance: patientInsurance.ehrInsurance || null,
          insurer: patientInsurance.insurer || null,
        });
      } else {
        eligibilityLogger.info(
          'Reset AppPatient- ehrPatient and patientSourceVaultTokens are not matched for same patient',
          {
            ehrPatient,
            patientLookupResponse,
          },
        );

        setPatient(undefined);
      }
    } else if (!shouldUseSourceVaultTokensLoading && !shouldUseSourceVaultTokens) {
      eligibilityLogger.info('Initialize appPatient without patientSourceVaultTokens');
      setPatient(ehrPatient);
    }
  }, [
    patientLookupResponse,
    ehrPatient,
    shouldUseSourceVaultTokens,
    shouldUseSourceVaultTokensLoading,
  ]);
}
